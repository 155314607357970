<template>
  <div class="content block-el">
    <PageHeader :title="$t('adm.create.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <CardComponent>
            <template #header>
              Novo Cliente
            </template>
            <FormComponent ref="store-account" @submit="create">
              <div class="row">
                <div class="col-3">
                  <InputComponent v-model="form.name" :label="$t('adm.create.comp-name')" required />
                </div>
                <div class="col-3">
                  <SelectComponent v-model="form.country" :label="$t('app.country')" :options="$store.state.countries"
                    text-field="name" value-field="iso" />
                </div>
                <div v-if="form.country == 'BR'" class="col-3">
                  <InputComponent v-model="form.tax_id" label="CNPJ" mask="##.###.###/####-##"
                    :required="form.type != 'trial'" @input="searchCompany" />
                  <div v-if="searchingCompany" class="preloader pl-xxs pls-primary">
                    <svg class="pl-circular" viewBox="25 25 50 50">
                      <circle class="plc-path" cx="50" cy="50" r="20"></circle>
                    </svg>
                  </div>
                </div>
                <div v-else class="col-3">
                  <InputComponent v-model="form.tax_id" label="Tax ID" :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.company_name" :label="$t('adm.create.corporate-name')"
                    :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <InputComponent type="email" v-model="form.billing_email" :label="$t('adm.create.billing-email')"
                    :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <SelectComponent v-model="form.antifraud" :label="$t('generic-str.menu.anti-fraud')"
                    :options="antifraud" required />
                </div>
              </div>
              <h5 class="card-title">{{ $t('generic-str.contract') }}</h5>
              <div class="row">
                <div class="col-3">
                  <SelectComponent v-model="form.type" :label="$t('generic-str.type')" :options="types" required />
                </div>
                <div class="col-3">
                  <SelectComponent v-model="form.currency" :label="$t('app.currency')"
                    :options="$store.state.currencies" text-field="name" value-field="code" required
                    @input="changeCurrency" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.balance" label="Saldo" v-money="money" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.due_date" label="Dias de Vencimento" :required="form.type != 'trial'" />
                </div>
              </div>
              <h5 class="card-title">
                {{ $t('app.address') }}
              </h5>
              <div class="row">
                <div class="col-3">
                  <InputComponent type="tel" v-model="form.postal_code" label="CEP" mask="#####-###"
                    :required="form.type != 'trial'" @input="searchAddress" />
                  <div v-if="searching" class="preloader pl-xxs pls-primary loading-cep">
                    <svg class="pl-circular" viewBox="25 25 50 50">
                      <circle class="plc-path" cx="50" cy="50" r="20" />
                    </svg>
                  </div>
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.street" label="Endereço" :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.number" label="Número" :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.complement" label="Complemento" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.district" label="Bairro" :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.city" label="Cidade" :required="form.type != 'trial'" />
                </div>
                <div class="col-3">
                  <SelectComponent v-model="form.province" :label="$t('generic-str.state')" :options="provinces"
                    :required="form.type != 'trial'" />
                </div>
              </div>
              <h5 class="card-title">
                {{ $t('generic-str.responsible') }}
              </h5>
              <div class="row">
                <div class="col-3">
                  <InputComponent type="email" v-model="form.owner.email" label="E-Mail" required />
                </div>
                <div class="col-3">
                  <InputComponent v-model="form.owner.name" label="Nome" required />
                </div>
                <div class="col-3">
                  <TelInputComponent v-model="form.owner.mobile_number" label="Celular" required />
                </div>
                <div class="col-3">
                  <PasswordInputComponent v-model="form.owner.password" label="Senha" required />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ButtonComponent variant="secondary" class="float-right ml-2" :loading="creating"
                    @click="$refs['store-account'].submit()">
                    <span class="material-symbols-outlined">
                      save
                    </span>
                  </ButtonComponent>
                </div>
              </div>
            </FormComponent>
          </CardComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import CustomerService from '@/services/customer.service';
import UserService from '@/services/user.service';
import { mask } from 'vue-the-mask';
import CardComponent from '@/components/CardComponent.vue';
import FormComponent from '@/components/form/FormComponent.vue';
import InputComponent from '@/components/form/InputComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import TelInputComponent from '@/components/form/TelInputComponent.vue';
import PasswordInputComponent from '@/components/form/PasswordInputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'CreateCustomer',
  components: {
    PageHeader,
    CardComponent,
    FormComponent,
    InputComponent,
    SelectComponent,
    TelInputComponent,
    PasswordInputComponent,
    ButtonComponent,
  },
  directives: {
    Tab,
    mask,
  },
  data() {
    return {
      creating: false,
      isSending: false,
      searchingCompany: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 4,
        masked: false,
      },
      antifraud: [
        {
          text: 'Aberto',
          value: 'open',
        },
        {
          text: 'Moderado',
          value: 'moderate',
        },
        {
          text: 'Restrito',
          value: 'strict',
        },
      ],
      types: [
        {
          text: 'Teste',
          value: 'trial',
        },
        {
          text: 'Pré-pago',
          value: 'prepaid',
        },
        {
          text: 'Semanal',
          value: 'weekly',
        },
        {
          text: 'Quinzenal',
          value: 'biweekly',
        },
        {
          text: 'Mensal',
          value: 'monthly',
        },
      ],
      salesExecutives: null,
      form: {
        country: 'BR',
        antifraud: 'moderate',
        type: 'trial',
        balance: 0,
        currency: 'BRL',
        salesId: null,
        owner: {
          email: '',
          mobile_number: '',
        },
      },
      searching: false,
      pages: 1,
    };
  },
  computed: {
    provinces() {
      const provinces = [];
      const storeProvinces = this.$store.state.provinces;
      Object.keys(this.$store.state.provinces).forEach((key, index) => {
        provinces.push({
          text: storeProvinces[key],
          value: key,
        });
      });
      return provinces;
    },
  },
  created() {
    this.fetchSalesExecutives();
  },
  methods: {
    fetchSalesExecutives() {
      UserService.getUsers({
        role: 'sales',
      }).then(
        (salesExecutives) => {
          this.salesExecutives = salesExecutives;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeCurrency() {
      this.$store.state.currencies.forEach((currency) => {
        console.log(currency);
        if (currency.code === this.form.currency) {
          this.money.prefix = `${currency.symbol} `;
          this.money.precision = currency.decimalDigits * 2;
        }
      });
    },
    create() {
      this.creating = true;
      CustomerService.createCustomer(this.form)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('adm.create.customer'),
              type: 'success',
            });
            this.$router.push('./');
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.creating = false;
        });
    },
    searchCompany() {
      if (this.form.tax_id.length >= 18 && !this.searchingCompany) {
        this.searchingCompany = true;
        this.$store
          .dispatch('searchCompany', { taxId: this.form.tax_id })
          .then((response) => {
            this.form.postal_code = response.address.postalCode;
            this.form.street = response.address.street;
            this.form.number = response.address.number;
            this.form.complement = response.address.complement;
            this.form.district = response.address.district;
            this.form.city = response.address.city;
            this.form.province = response.address.province;
            this.form.company_name = response.companyName;
            this.form.billing_email = response.email;
          })
          .catch((error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          })
          .finally(() => {
            this.searchingCompany = false;
          });
      }
    },
    searchAddress() {
      if (this.form.postal_code && this.form.postal_code.length === 9) {
        this.searching = true;
        axios
          .get(`https://viacep.com.br/ws/${this.form.postal_code}/json/`)
          .then((response) => {
            this.searching = false;
            this.form.street = response.data.logradouro;
            this.form.district = response.data.bairro;
            this.form.city = response.data.localidade;
            this.form.province = response.data.uf;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 43px;
  right: 30px;

  &.pls-primary .plc-path {
    stroke: #635ebe;
  }
}
</style>
